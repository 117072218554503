import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    return (
      <Layout location={this.props.location}>
        <Hero
          image={false}
          title={"De bedste sammenligninger af produkter"}
        />
        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(filter: { node_locale: {eq: "da-DK"} }, sort: { fields: [publishDate], order: DESC }, limit: 8) {
      nodes {
        node_locale
        id
        title
        slug
        publishDate(formatString: "D/M-YYYY")
        description {
          raw
        }
        image {
            gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
            )
        }
      }
    }
  }
`
